<template>
    <!-- class="addition" -->
    <div>
        <!-- <div class="headline headline--sm flex-auto">
            <h1 class="headline__title">Track the Dispatch</h1>
            <p class="headline__text">Check all dispatch information</p>
        </div> -->

        <div class="track_dispatch_wrpr" v-if="dispatch && dispatch.companies">
            <div>
                <div class="dispatch_companies_row_box" v-for="(item) in dispatch.companies.items" :key="item.id">
                    <div class="dispatch_companies_row" v-if="item.companyID !== dispatch.owner.userCompanyId && item.company">
                        <div class="track_dispatch_company">
                            <div class="item_header">
                                <div>
                                    <span style="color: #26A7E1;">{{ dispatch.owner.company.company_name }}</span> / {{ dispatch.owner.company.companyUserId }}
                                </div>
                            </div>
                            <div class="item_footer">
                                {{ $translate('batch') }}: {{ dispatch.batches[0] ? dispatch.batches[0].id : '' }}
                                <a 
                                    @click="$set(item,'dispatchesConditionsOpen',!item.dispatchesConditionsOpen )" class="btn btn-primary btn-standart" 
                                    :title="item.dispatchesConditionsOpen ? 'Close conditions' : 'View conditions'" :class="{'conditions_close_btn': item.dispatchesConditionsOpen}"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 612 612" style="enable-background:new 0 0 612 612;" xml:space="preserve">
                                        <path d="M609.608,315.426c3.19-5.874,3.19-12.979,0-18.853c-58.464-107.643-172.5-180.72-303.607-180.72 S60.857,188.931,2.393,296.573c-3.19,5.874-3.19,12.979,0,18.853C60.858,423.069,174.892,496.147,306,496.147 S551.143,423.069,609.608,315.426z M306,451.855c-80.554,0-145.855-65.302-145.855-145.855S225.446,160.144,306,160.144 S451.856,225.446,451.856,306S386.554,451.855,306,451.855z" style="fill: #fff;"/>
                                        <path d="M306,231.67c-6.136,0-12.095,0.749-17.798,2.15c5.841,6.76,9.383,15.563,9.383,25.198c0,21.3-17.267,38.568-38.568,38.568 c-9.635,0-18.438-3.541-25.198-9.383c-1.401,5.703-2.15,11.662-2.15,17.798c0,41.052,33.279,74.33,74.33,74.33 s74.33-33.279,74.33-74.33S347.052,231.67,306,231.67z" style="fill: #fff;"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <div class="dispatch_delivery_icon">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 491.1 491.1" style="enable-background:new 0 0 491.1 491.1;" xml:space="preserve" v-if="!getCompanyStatus(item.companyID).receive_date">
                                <g transform="translate(0 -540.36)">
                                    <path d="M401.5,863.31c-12,0-23.4,4.7-32,13.2c-8.6,8.6-13.4,19.8-13.4,31.8s4.7,23.2,13.4,31.8c8.7,8.5,20,13.2,32,13.2 c24.6,0,44.6-20.2,44.6-45S426.1,863.31,401.5,863.31z M401.5,933.31c-13.8,0-25.4-11.4-25.4-25s11.6-25,25.4-25 c13.6,0,24.6,11.2,24.6,25S415.1,933.31,401.5,933.31z"/>
                                    <path d="M413.1,713.41c-1.8-1.7-4.2-2.6-6.7-2.6h-51.3c-5.5,0-10,4.5-10,10v82c0,5.5,4.5,10,10,10h81.4c5.5,0,10-4.5,10-10v-54.9 c0-2.8-1.2-5.5-3.3-7.4L413.1,713.41z M426.5,792.81h-61.4v-62.1h37.4l24,21.6V792.81z"/>
                                    <path d="M157.3,863.31c-12,0-23.4,4.7-32,13.2c-8.6,8.6-13.4,19.8-13.4,31.8s4.7,23.2,13.4,31.8c8.7,8.5,20,13.2,32,13.2 c24.6,0,44.6-20.2,44.6-45S181.9,863.31,157.3,863.31z M157.3,933.31c-13.8,0-25.4-11.4-25.4-25s11.6-25,25.4-25 c13.6,0,24.6,11.2,24.6,25S170.9,933.31,157.3,933.31z"/>
                                    <path d="M90.6,875.61H70.5v-26.6c0-5.5-4.5-10-10-10s-10,4.5-10,10v36.6c0,5.5,4.5,10,10,10h30.1c5.5,0,10-4.5,10-10 S96.1,875.61,90.6,875.61z"/>
                                    <path d="M141.3,821.11c0-5.5-4.5-10-10-10H10c-5.5,0-10,4.5-10,10s4.5,10,10,10h121.3C136.8,831.11,141.3,826.71,141.3,821.11z"/>
                                    <path d="M30.3,785.01l121.3,0.7c5.5,0,10-4.4,10.1-9.9c0.1-5.6-4.4-10.1-9.9-10.1l-121.3-0.7c-0.1,0-0.1,0-0.1,0 c-5.5,0-10,4.4-10,9.9C20.3,780.51,24.8,785.01,30.3,785.01z"/>
                                    <path d="M50.7,739.61H172c5.5,0,10-4.5,10-10s-4.5-10-10-10H50.7c-5.5,0-10,4.5-10,10S45.2,739.61,50.7,739.61z"/>
                                    <path d="M487.4,726.11L487.4,726.11l-71.6-59.3c-1.8-1.5-4-2.3-6.4-2.3h-84.2v-36c0-5.5-4.5-10-10-10H60.5c-5.5,0-10,4.5-10,10 v73.2c0,5.5,4.5,10,10,10s10-4.5,10-10v-63.2h234.8v237.1h-82c-5.5,0-10,4.5-10,10s4.5,10,10,10h122.1c5.5,0,10-4.5,10-10 s-4.5-10-10-10h-20.1v-191.1h80.6l65.2,54l-0.7,136.9H460c-5.5,0-10,4.5-10,10s4.5,10,10,10h20.3c5.5,0,10-4.4,10-9.9l0.8-151.6 C491,730.91,489.7,728.01,487.4,726.11z"/>
                                </g>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 52 52" style="enable-background:new 0 0 52 52;" xml:space="preserve" v-if="getCompanyStatus(item.companyID).receive_date">
                                <path d="M26,0C11.664,0,0,11.663,0,26s11.664,26,26,26s26-11.663,26-26S40.336,0,26,0z M26,50C12.767,50,2,39.233,2,26 S12.767,2,26,2s24,10.767,24,24S39.233,50,26,50z" style="fill: #26A7E1;"/>
                                <path d="M38.252,15.336l-15.369,17.29l-9.259-7.407c-0.43-0.345-1.061-0.274-1.405,0.156c-0.345,0.432-0.275,1.061,0.156,1.406 l10,8C22.559,34.928,22.78,35,23,35c0.276,0,0.551-0.114,0.748-0.336l16-18c0.367-0.412,0.33-1.045-0.083-1.411 C39.251,14.885,38.62,14.922,38.252,15.336z" style="fill: #26A7E1;"/>
                            </svg>
                        </div>
                        <div class="track_dispatch_company condition_disabled">
                            <div class="item_header">
                                <div>
                                    <span style="color: #26A7E1;">{{ item.company.company_name }}</span> / {{ item.company.companyUserId }}
                                </div>
                            </div>
                            <div class="item_footer">
                                {{ $translate('batch') }}: {{ dispatch.batches[0] ? dispatch.batches[0].id : '' }}
                                <a
                                    @click="$set(item,'dispatchesConditionsOpen',!item.dispatchesConditionsOpen )" class="btn btn-primary btn-standart" 
                                    :title="item.dispatchesConditionsOpen ? 'Close conditions' : 'View conditions'" :class="{'conditions_close_btn': item.dispatchesConditionsOpen}"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 612 612" style="enable-background:new 0 0 612 612;" xml:space="preserve">
                                        <path d="M609.608,315.426c3.19-5.874,3.19-12.979,0-18.853c-58.464-107.643-172.5-180.72-303.607-180.72    S60.857,188.931,2.393,296.573c-3.19,5.874-3.19,12.979,0,18.853C60.858,423.069,174.892,496.147,306,496.147    S551.143,423.069,609.608,315.426z M306,451.855c-80.554,0-145.855-65.302-145.855-145.855S225.446,160.144,306,160.144    S451.856,225.446,451.856,306S386.554,451.855,306,451.855z" style="fill: #fff;"/>
                                        <path d="M306,231.67c-6.136,0-12.095,0.749-17.798,2.15c5.841,6.76,9.383,15.563,9.383,25.198c0,21.3-17.267,38.568-38.568,38.568    c-9.635,0-18.438-3.541-25.198-9.383c-1.401,5.703-2.15,11.662-2.15,17.798c0,41.052,33.279,74.33,74.33,74.33    s74.33-33.279,74.33-74.33S347.052,231.67,306,231.67z" style="fill: #fff;"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="conditions_list" v-if="item.dispatchesConditionsOpen && item.companyID !== dispatch.owner.userCompanyId">
                        <ConditionsTab :conditions="dispatch.contract_conditions" :deviceDataDuringSC="true" />
                    </div>
                </div>
            </div>
            <div class="transaction_history_table">
                <h2 class="heading-page-h1">{{ $translate('transaction-history') }}:</h2>
                <div class="transaction_history_card_wrpr" v-if="products.length">
                    <ul class="transaction_history_card" v-for="thData in rows" :key="thData.id">
                        <template v-if="getProductData(thData.batches[0].products[0].id)">
                            <li><span>{{ $translate('product-name') }}:</span> {{ getProductData(thData.batches[0].products[0].id).title }}</li>
                            <li><span>{{ $translate('quantity/units') }}:</span> {{ getProductData(thData.batches[0].products[0].id).quantity }}/{{ getProductData(thData.batches[0].products[0].id).units }}</li>
                        </template>
                        <template v-else>
                            <li><span>{{ $translate('product-name') }}:</span> -</li>
                            <li><span>{{ $translate('quantity/units') }}:</span> -</li>
                        </template>
                        <li><span>{{ $translate('batch-id') }}:</span> {{ thData.batches[0].id }}</li>
                        <li><span>{{ $translate('name-of-the-dispatch') }}:</span> {{ thData.dispatchID }}</li>
                        <li><span>{{ $translate('date') }}:</span> {{ new Date(+thData.companiesStatus.find(itemF => itemF.receive_date).receive_date).toDateString() }}</li>
                        <li><span>{{ $translate('type') }}:</span> {{ thData.companiesStatus.find(itemF => itemF.receive_date).type }}</li>
                        <li><span>{{ $translate('conditions') }}:</span> {{ thData.contract_conditions.length }}</li>
                        <li><span>{{ $translate('status-of-the-product') }}:</span> Sold</li>
                    </ul>
                </div>
                <div class="transaction_history_card_wrpr processing" v-else style="min-height: 200px;"></div>
                <Pagination v-model="filteredRows" :items="rawSource" textPerPage="Products per page:" />
            </div>
        </div>
    </div>
</template>

<script>
import { API, Storage } from "aws-amplify";
import { getDispatch, getCompany, getProduct, getDevice, listProducts } from "@/graphql/queries";
// import * as graphqlMutations from "@/graphql/mutations";

import Base from "@/components/base";
import { mixTables,mixDeleteItem } from "@/mixins";
// import { mixDispatches } from "@/mixins/dispatch";
// import { statuses } from "@/config/dispatch";

import ConditionsTab from "@/components/tabs/ConditionsTab";

export default {
    name: "TrackTheDispatch",
    mixins: [mixTables, ],
    components: {
        ...Base,
        ConditionsTab,
    },
    data() {
        return {
            companiesData: [],

            filters: {
                type: "All Types",
                startDate: null,
                endDate: null,
                timeStart: null,
                timeEnd: null,
            },
            sortField: "Date",

            source:"dispatches",
            columns: [
                { name: "Product Name", filter: "productName" },
                { name: "Quantity/units", filter: "" },
                { name: "Batch id", filter: "" },
                { name: "Name of the dispatch", filter: "" },
                { name: "Date", filter: "" },
                { name: "Type", filter: "" },
                { name: "Conditions", filter: "" },
                { name: "Status of the product", filter: "" },
            ],
            products: [],
            calculatedFields: [],
            returnedDispatches: [],
            updateKey: 0,
            searchField: "dispatchID",
            dispatch: null,
            dispatchesConditionsOpen: false
        }
    },
    computed: {
        // dispatch(){
        //     return this.$store.state.dispatches.find(item => item.id === this.$route.params.id) || [];
        // },
        // conditionsTabData(){
        //     let dispatchConditions = [...dispatch.contract_conditions];
        //     dispatchConditions.forEach(item => {
        //         item.batch = ;
        //         item.devices = ;
        //     })
        //     // .select_batch_id;
        //     // .select_device;
        // },
        sourceData(){
            return this.returnedDispatches;
        },
        sortFieldsForDropdown(){
            return  [
                { name: "Date creation", filter: "createdAt" },
                { name: "Update At", filter: "updatedAt" },
                { name: "Name", filter: "dispatchID" },
            ]
        },
    },
    methods: {
        getCompanyStatus(id){
            let findStatus = this.dispatch.companiesStatus.find(item => item.id === id);
            if(findStatus) {
                return findStatus;
            } else {
                return false;
            }
        },

        getProductData(id){
            if(id.id){
                return id;
            } else {
                return this.products.find(item => item.id === id);
            }
        },
        async getTransactionsData(){
            this.dispatch = this.$store.state.dispatches.find(item => item.id === this.$route.params.id) || null;

            if(!this.$store.state.dispatches.length){
                setTimeout(() => {
                    this.getTransactionsData();
                    return false;
                }, 2000);
            } else {
                for(let i of this.dispatch.companies.items){
                    let company = await API.graphql({
                        query: getCompany,
                        variables: {
                            id: i.companyID,
                        },
                    }).then((res) => res.data.getCompany);
                    // i.company=
                    this.$set(i,'company',company)
                }
                this.dispatch.companiesStatus.forEach(cStatus => {
                    if(
                        (cStatus.receive_date || (cStatus.status === 'completed' && 
                        cStatus.receive_date)) && (!this.returnedDispatches.length ||
                        !this.returnedDispatches.map(mItem => mItem.id).includes(this.dispatch.id))
                    ){
                        this.returnedDispatches.push(this.dispatch);
                    }
                });

                this.sourceData.forEach(async element => {
                    let requests = element.companies.items.map(async cItem => {
                        const filter = { companyProductsId: { eq: cItem.companyID } }
                        return await API.graphql({
                            query: listProducts,
                            variables: {
                                filter
                            }
                        }).then(res => res.data.listProducts.items);
                    });
                    await Promise.all(requests).then(res => {
                        this.products.push(res.flat());
                    });
                });

                let batchesRequest = this.dispatch.batches.map(async b => {
                    let newBatch = {...b};

                    let requests = newBatch.products.map(async batchProductID => {
                        return await API.graphql({
                            query: getProduct,
                            variables: {
                                id: batchProductID,
                            },
                        }).then((res) => res.data.getProduct);
                    })
                    await Promise.all(requests).then(res => newBatch.products = res)
                    return newBatch
                })
                await Promise.all(batchesRequest).then(res => this.dispatch.batches = res);

                if(this.dispatch.contract_conditions){
                    let requests = this.dispatch.contract_conditions.map(async c => {
                        c.batch = this.dispatch.batches.find(b => b.id === c.select_batch_id)
                        if (c.condition_type === "business"){
                            return c
                        }
                        if(c.condition_type=="smart"){
                            c.devices = await API.graphql({
                                query: getDevice,
                                variables: {
                                    id: c.select_device,
                                },
                            }).then((res) => res.data.getDevice);
                            
                            return c
                        }
                    })
                    await Promise.all(requests).then(res => this.dispatch.contract_conditions = res)
                }

                setTimeout(() => {
                    this.products = this.products.flat();
                    this.updateKey++;
                }, 1000);
            }
        }
    },
    created(){
        // const company = await API.graphql({
        //     query: getCompany,
        //     variables: {
        //         id: userCompanyId
        //     }
        // })
        // companyData = company.data.getCompany;
    },
    mounted(){
       this.getTransactionsData();
    }
};
</script>
